import { gql } from 'graphql-request';
import { request } from '../graphql';

export const GET_LUZMO_SSO = gql`
  query luzmoSso($input:LuzmoSsoInput!) {
    luzmoSsoAdmin(input:$input) {
      id
      token
    }
  }
`;

export type GetLuzmoSsoData = {
  luzmoSsoAdmin: {
    id: string;
    token: string;
  }
}

interface GetLuzmoSsoAdminArgs {
  realTime: boolean;
  accessOfDashboard?: string;
  forCompany?: string;
}
export const getLuzmoSsoAdmin = (args: GetLuzmoSsoAdminArgs) =>
  request<GetLuzmoSsoData>(GET_LUZMO_SSO, { input: { ...args } });
