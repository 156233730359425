import { gql } from 'graphql-request';
import { request } from '../graphql';

export const GET_LUZMO_SSO = gql`
  query luzmoSso($realTime:Boolean!,$accessOfDashboard:String) {
    luzmoSSO(realTime:$realTime,accessOfDashboard:$accessOfDashboard) {
      id
      token
    }
  }
`;

export type GetLuzmoSsoData = {
  luzmoSSO: {
    id: string;
    token: string;
  }
}

interface GetLuzmoSsoArgs {
  realTime: boolean;
  accessOfDashboard?: string;
}
export const getLuzmoSso = (args: GetLuzmoSsoArgs) =>
  request<GetLuzmoSsoData>(GET_LUZMO_SSO, { realTime: args.realTime, accessOfDashboard: args.accessOfDashboard  });
