import { Accessor, createMemo, createResource } from 'solid-js';
import { getLuzmoSso } from '../requests/evoluno';
import { getLuzmoSsoAdmin } from '../requests/evoluno/queries/getLuzmoSsoAdmin';

export const createLuzmoSso = (userValid: Accessor<boolean>, companyOverride: Accessor<string | undefined>) => {
  const luzmoSsoSource = createMemo(() => {
    const forCompany = companyOverride();
    if (forCompany) {
      return forCompany
    } else {
      return userValid();
    }
  })
  return createResource(
    luzmoSsoSource,
    async (userOrCompany) => {
      const forCompany = typeof userOrCompany === 'string' ? userOrCompany : undefined;
      if (forCompany) {
        const data = await getLuzmoSsoAdmin({realTime: true, forCompany});
        return data.luzmoSsoAdmin;
      } else {
        const data = await getLuzmoSso({realTime: true});
        return data.luzmoSSO;
      }
    });
};
