import { Accessor, createResource } from 'solid-js';
import { getLuzmoSso } from '../requests/evoluno';
import { getLuzmoSsoAdmin } from '../requests/evoluno/queries/getLuzmoSsoAdmin';

export const createLuzmoSsoSurvey = (dashboardAccess: Accessor<string | undefined>, companyOverride: Accessor<string | undefined>) => {
  return createResource(
    () => {
      const accessOfDashboard = dashboardAccess();
      return accessOfDashboard
        ? {
          accessOfDashboard: dashboardAccess(),
          forCompany: companyOverride(),
        }
        : undefined;
    },
    async ({ accessOfDashboard, forCompany }) => {
      if (forCompany) {
        const data = await getLuzmoSsoAdmin({ realTime: false, accessOfDashboard, forCompany });
        return data.luzmoSsoAdmin;
      } else {
        const data = await getLuzmoSso({ realTime: false, accessOfDashboard});
        return data.luzmoSSO;
      }
    }
  );
};
